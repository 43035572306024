import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    server: "https://remediation.sabanciuniv.edu:5001"
  },
  getters: {
    server(state){
      return state.server;
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth
  }
})
