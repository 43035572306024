<template>
    <div id="VulnDevView" class="container col-10">
        <VulnDevContent />
        
        <div class="row">
            <div class="col-12 col-md-0">
                   <form
                     class="form-inline d-flex mx-1 justify-content-end"
                     @submit.stop.prevent="doSearch"
                   >
                     <div class="input-group">
                       <input
                         v-model="quickSearch"
                         type="search"
                         placeholder="Quick search"
                         class="form-control"
                       >
                       <div class="input-group-append">
                         <button
                           type="submit"
                           class="btn btn-outline-secondary"
                         >
                           <i class="mdi mdi-magnify" /> Go
                         </button>
                       </div>
                     </div>
                   </form>
            </div>
         </div>
<!--         <div class="container" v-if="currentUser">
 -->            
              <!-- Using the VdtnetTable component -->
              <vdtnet-table
                ref="table"
                :fields="fields"
                :opts="options"
                :select-checkbox="1"
                @edit="doAlertEdit"
                @delete="doAlertDelete"
                @reloaded="doAfterReload"
                @table-creating="doCreating"
                @table-created="doCreated"
              >
              </vdtnet-table>
<!--         </div>

 -->

     </div>

</template>

<script>
import Navbar from '../components/Navbar';
import VulnDevContent from '../components/Dashboard/VulnDev';
import Footer from '../components/Footer';
import authHeader from '../services/auth-header';
import axios from 'axios';

//import VdtnetTable from 'vue-datatables-net';
import VdtnetTable from '../VdtnetTable'

import 'datatables.net-bs4'

// import buttons and plugins
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-responsive/js/dataTables.responsive.js'
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4.js'

// import the rest
import 'datatables.net-buttons-bs4'
import 'datatables.net-select-bs4'

import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css'
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.css'


export default {
    name: "VulnDevView",
    components: {Navbar, VulnDevContent, VdtnetTable, Footer},
    data(){
        const vm = this

        return{
            loading: true,
            rows: [],
            logintype: null,
          options: {
            buttons: ['copy', 'csv', 'print', 'pdf'],
/* eslint-disable */
            dom: "Btr<'row vdtnet-footer'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'pl>>",
/* eslint-enable */
            responsive: true,
            processing: true,
            searching: true,
            searchDelay: 1500,
            destroy: true,
            ordering: true,
            lengthChange: true,
            serverSide: false,
            fixedHeader: true,
            saveState: true,
            select:{
                style: "multi"
            }
          },
          fields: {
            email: { label: 'Abuse Mail', sortable: true, searchable: true },
            ip_addr: { label: 'IP Address', sortable: true, searchable: true },
            type: { label: 'Service', sortable: true, searchable: true }
          },
          quickSearch: ''
        }
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      }
    },
    beforeCreate(){
        console.log("Logging from beforeCreate.")
        if(this.$route.path.includes("nas")) this.logintype = 0;
        else if(this.$route.path.includes("elasticsearch")) this.logintype = 1;
    },
    created(){
        console.log("In created: ", this.rows)
    },
    mounted(){
      console.log("Logging from Mounted.")
      if (!this.currentUser) {
        this.$router.push('/');
      }else{
        let datatype = "";
        console.log("Logging the type: ", this.type)
      if (this.type == null){
        if(this.$route.path.includes("nas")) this.logintype = 0;
        else if(this.$route.path.includes("elasticsearch")) this.logintype = 1;

        }
        console.log("Logging the logintype: ", this.logintype)
          if (this.logintype === 0){
                datatype = 'nas';
            }
            else if (this.logintype === 1){
                datatype = 'elasticsearch';
            }
  
            this.loading = true;
            axios.get("https://remediation.sabanciuniv.edu:5001/api/get/" + datatype, 
              { headers: authHeader() }).then(response => {
                console.log("Logging Response: ", response)
                if (response.data.result == 0){
                  this.$store.dispatch('auth/logout');
                  this.$router.push('/');
                }else{
                  this.rows = response.data.data
                  console.log("Logging mounted rows: ", this.rows)
                  this.loading = false
                  console.log(this.$refs.table)
                  this.$refs.table.setTableData(this.rows)
                }
              })
      }
        
    },
    methods: {
        doLoadTable(cb) {
            console.log("In DoLoad: ", this.rows)
         },
         doAlertEdit(data) {
           window.alert(`row edit click for item ID: ${data.id}`)
         },
         doAlertDelete(data, row, tr, target) {
           window.alert(`deleting item ID: ${data.id}`)
           // row.remove() doesn't work when serverside is enabled
           // so we fake it with dom remove
           tr.remove()
           const table = this.$refs.table
           setTimeout(() => {
             // simulate extra long running ajax
             table.reload()
           }, 1500)
         },
         doAfterReload(data, table) {
           console.log('data reloaded')
         },
         doCreating(comp, el) {
           console.log('creating')
         },
         doCreated(comp) {
           console.log('created')
         },
         doSearch() {
           this.$refs.table.search(this.quickSearch)
         },
         doExport(type) {
           const parms = this.$refs.table.getServerParams()
           parms.export = type
           window.alert('GET /api/v1/export?' + jQuery.param(parms))
         },
         formatCode(zipcode) {
           return zipcode.split('-')[0]
         },
        retrieveData(){
            let datatype = "";
            if (this.logintype == 0){
                datatype = 'nas';
            }
            else if (this.type.raw == "elasticsearch"){
                datatype = 'elasticsearch';
            }
            
            setTimeout(() => {  console.log("Sleeping.!"); }, 2000);

            this.loading = true;
            axios.get("https://remediation.sabanciuniv.edu:5001/api/get/" + datatype, 
              { headers: authHeader() }).then(response => {
                this.rows = response.data.data
                console.log("Logging: ", this.rows)
                this.loading = false
                return response.data.data
              })
        }
    }
}
</script>

<style scoped>

</style>
