<template>
    <div id="Login">
        
        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-12 text-center">
                    <h3 style="font-weight: bold;">
                        {{ type.beautified }} Login
                    </h3>
                </div>
                <div class="col-lg-4 col-sm-12 offset-lg-4">
                    <div class="card border-sabanci">
                        <div class="card-body">

                            <div v-if="alert.isAlert" class="row mb-3">
                                <div class="col-lg-10 offset-lg-1 col-sm-12">
                                    <div class="alert" :class="{'alert-danger': alert.isDanger, 'alert-success': !alert.isDanger}" v-html="alert.message"></div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-10 offset-lg-1 col-sm-12">
                                    <label>E-mail</label>
                                    <input type="text" v-model="user.email" class="form-control" placeholder="johndoe@domain.com">
                                </div>    
                            </div>

                            <div class="row mt-3">
                                <div class="col-lg-10 offset-lg-1 col-sm-12">
                                    <label>Password</label>
                                    <input type="password" v-model="user.password" class="form-control" placeholder="*********">
                                </div>    
                            </div>

                            <div class="row mt-4 mb-4">
                                <div class="col-lg-8 offset-lg-2 col-sm-12">
                                    <button @click="doLogin" ref="loginButton" class="btn btn-primary btn-block"><i class="fas fa-sign-in-alt"></i> Login</button>
                                </div>
                            </div>

                            <hr>

                            <div class="row mt-4 text-center">
                                <div class="col-lg-10 offset-lg-1 col-sm-12">
                                    <p>
                                        If the given credentials are faulty, 
                                        please get in touch with us via email 
                                        (<a href="mailto:test@sabanciuniv.edu">securitynotification@sabanciuniv.edu</a>)
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import User from '../../models/users';

export default {
    name: "LoginContent",
    data(){
        return {
            user: new User('', ''),
            form: {
                email: "",
                password: "",
            },
            alert: {
                isAlert: false,
                isDanger: false,
                message: ""
            },
            type: {
                raw: this.$route.params.type,
                beautified: (this.$route.params.type.charAt(0).toUpperCase() + this.$route.params.type.slice(1))
            }
        }
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },
    created() {
      if (this.loggedIn) {
        if (this.type.raw === "nas"){
        this.$router.push('/vulnerable_devices/nas');
        }else if(this.type.raw === "elasticsearch"){
        this.$router.push('/vulnerable_devices/elasticsearch');
        }

      }
      if(this.type.raw !== "nas" && this.type.raw !== "elasticsearch") this.$router.push("/");
      this.setData();

    },
    watch: {
        '$route.params.type': function (id) {
            this.setData()
        }
    },
    methods: {
        async doLogin(){
            this.alert.isAlert = false;
            let btn = this.$refs.loginButton;
            let data = {
                email: this.form.email,
                password: this.form.password,
                type: (this.type.raw === "elasticsearch" ? 0 : (this.type.raw === "nas" ? 1 : undefined))
            }

            if(this.user.email.length === 0 || this.user.password.length === 0){
                this.raiseAlert("Please do not leave any input empty!");
                return;
            }
            if(data.type === undefined) this.$router.push("/");

            $(btn).html(`<i class="fas fa-spinner fa-spin"></i>`);


            if (this.user.email && this.user.password) {
              this.$store.dispatch('auth/login', this.user).then(
                (response_data) => {
                  if (this.loggedIn){
                      if (this.type.raw === "nas"){
                      this.$router.push('/vulnerable_devices/nas');
                      }else if(this.type.raw === "elasticsearch"){
                      this.$router.push('/vulnerable_devices/elasticsearch');
                      }
                  }
                },
                error => {
                    this.raiseAlert("Something went wrong. Please try again.");
                    $(btn).html(`<i class="fas fa-sign-in-alt"></i> Login`);
                }
              );
            }

            $(btn).html(`<i class="fas fa-sign-in-alt"></i> Login`);
        },
        raiseAlert(message, isDanger = true){
            this.alert.isAlert = true;
            this.alert.isDanger = isDanger;
            this.alert.message = message;
        },
        setData(){
            this.type = {
                raw: this.$route.params.type,
                beautified: (this.$route.params.type.charAt(0).toUpperCase() + this.$route.params.type.slice(1))
            }
        }
    }
}
</script>

<style>

</style>