<template>
    <div id="NASAdvice">
        <div class="container-fluid mt-5">

            <div class="row">
                <div class="col-12 text-center">
                    <h1>QNAP NAS Vulnerability Remediation</h1>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-lg-6 col-sm-12">
                    <div class="card width-80">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 text-left">
                                    <h4 class="text-center">Are you affected?</h4>
                                    Vulnerability is fixed these issues in the following versions of Photo Station:
                                    <ul>
                                    <li>QTS 4.3.6: Photo Station 5.7.11 and later</li>
                                    <li>QTS 4.4.3: Photo Station 6.0.10 and later</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-12">
                    <div class="card width-80">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 text-left">
                                    <h4 class="text-left">How to fix it</h4>
                                    Updating Photo Station:
                                    <ol type="1">
                                        <li>Log on to QTS as administrator.</li>
                                        <li>Open the App Center and then click on magnifier</li>
                                        <ol type="a">
                                            <li>A search box appears.</li>
                                        </ol>
                                        <li>Type “Photo Station” and then press ENTER.</li>
                                        <ol type="a">
                                            <li>Photo Station appears in the search results.</li>
                                        </ol>
                                        <li>Click Update.</li>
                                        <ol type="a">
                                            <li>A confirmation message appears.</li>
                                            <li>Note: The Update button is not available if your version is already up to date.</li>
                                        </ol>
                                        <li>Click OK.</li>
                                        <ol type="a">
                                            <li>The application is updated.</li>
                                        </ol>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card width-80">
                     <div class="card-body">
                        <div class="col-12 text-center">
                            For more details about the vulnerability visit:</br>
                            <a href="https://www.qnap.com/en/security-advisory/qsa-20-11">https://www.qnap.com/en/security-advisory/qsa-20-11</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "NASAdvice"
}
</script>

<style>

</style>