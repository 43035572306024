<template>
    <div id="AdviceView">
        <NASAdvice v-if="$route.params.type==='nas'" />
        <ElasticAdvice v-else-if="$route.params.type==='elasticsearch'" />
    </div>
</template>

<script>
import NASAdvice from '../components/Advices/Nas';
import ElasticAdvice from '../components/Advices/Elastic.vue';
export default {
    name: "Advice",
    components: {NASAdvice, ElasticAdvice}
}
</script>

<style>

</style>