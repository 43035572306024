import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue';
import LoginView from '../views/Login.vue';
import AdviceView from '../views/Advice.vue';
import VulnDevView from '../views/VulnDev.vue';
import FAQView from '../views/FAQ.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login/:type',
    name: "Login",
    component: LoginView
  },
  {
    path: '/advice/:type',
    name: "Advice",
    component: AdviceView
  },
  {
    path: '/vulnerable_devices/:type',
    name: "VulnDev",
    component: VulnDevView
  },
  {
    path: '/FAQ',
    name: "FAQ",
    component: FAQView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
