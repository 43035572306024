import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://remediation.sabanciuniv.edu:5001/api/get/';

class UserService {
/*  getElasticSearch() {
    return axios.get(API_URL + 'elasticsearch');
  }

  getNas() {
    return axios.get(API_URL + 'nas');
  }*/

  getElasticSearch() {
    axios.get(API_URL + 'elasticsearch', 
      { headers: authHeader() }).then(response => {
        return response.data;
      });
  }

  getNas() {
    axios.get(API_URL + 'nas', 
      { headers: authHeader() }).then(response => {
        return response.data;
      });  
  }

/*  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }*/
}

export default new UserService();