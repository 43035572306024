<template>
    <div id="FAQView">
        <FAQ />
    </div>
</template>

<script>
import FAQ from '../components/FAQ.vue';

export default {
    name: "FAQView",
    components: {FAQ}
}
</script>

<style>

</style>