<template>
    <div id="LoginView">
        <LoginContent />
    </div>
</template>

<script>
import Navbar from '../components/Navbar';
import LoginContent from '../components/Dashboard/Login';
import Footer from '../components/Footer';
export default {
    name: "LoginView",
    components: {Navbar, LoginContent, Footer},
    data(){
        return{
            logintype: 0
        }
    },
    beforeCreate(){
        if(this.$route.path.includes("nas")) this.logintype = 0;
        else if(this.$route.path.includes("elasticsearch")) this.logintype = 1;
    }
}
</script>

<style>

</style>