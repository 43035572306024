<template>
    <div id="FAQ">
        <div class="container-fluid mt-5">

            <div class="row">
                <div class="col-12 text-center">
                    <h2>Frequently Asked Questions</h2>
                </div>
            </div>

<!--             <div class="row mt-4">
                <div class="col-lg-6 col-sm-12">
                    <div class="card width-80">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 text-left">
                                    <h4 class="text-center">Are you affected?</h4>
                                    Vulnerability is fixed these issues in the following versions of Photo Station:
                                    <ul>
                                    <li>QTS 4.3.6: Photo Station 5.7.11 and later</li>
                                    <li>QTS 4.4.3: Photo Station 6.0.10 and later</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-12">
                    <div class="card width-80">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 text-left">
                                    <h4 class="text-left">How to fix it</h4>
                                    Updating Photo Station:
                                    <ol type="1">
                                        <li>Log on to QTS as administrator.</li>
                                        <li>Open the App Center and then click on magnifier</li>
                                        <ol type="a">
                                            <li>A search box appears.</li>
                                        </ol>
                                        <li>Type “Photo Station” and then press ENTER.</li>
                                        <ol type="a">
                                            <li>Photo Station appears in the search results.</li>
                                        </ol>
                                        <li>Click Update.</li>
                                        <ol type="a">
                                            <li>A confirmation message appears.</li>
                                            <li>Note: The Update button is not available if your version is already up to date.</li>
                                        </ol>
                                        <li>Click OK.</li>
                                        <ol type="a">
                                            <li>The application is updated.</li>
                                        </ol>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <div class="card width-80">
                     <div class="card-body">
                        <div class="col-12 text-left">
                            1. Who are we?<br>
                            <ul>
                              <li>We are cyber security researchers from <a href="https://www.sabanciuniv.edu/en">Sabanci University</a>. Our aim is to facilitate remediation of vulnerable servers by helping internet mediaries and online resource owners.<br>
                              </li>
                            </ul>
                            2. Why are you pinging my computer?<br>
                            <ul>
                              <li>We carry out Internet-wide measurements in order to detect vulnerable devices and inform owners.</li>
                              <li>The idea is to be able to detect misconfigured Elasticsearch services and vulnerable QNAP NAS devices open to public internet. Then inform the owners and observe user behaviour after notification.<br>
                              </li>
                            </ul>
                            3. What information do you get from my computer?<br>
                            <ul>
                              <li>We are currently scanning for vulnerable QNAP NAS devices and unauthenticated Elasticsearch servers.<br>
                              </li>
                            </ul>
                            4. What tools do we use?<br>
                            <ul>
                              <li><a href="https://github.com/robertdavidgraham/masscan">Masscan</a>, an open-source tool<br>
                              </li>
                              <li>
                                Custom scripts for sending non-intrusive requests.
                              </li>
                            </ul>
                            5. Is it possible to verify a request originating from us?<br>
                            <ul>
                                <li>We are sending our requests with the user agent set as <strong>"Mozilla/5.0 (+https://remediation.sabanciuniv.edu/)"</strong>
                                </li>
                                <li>
                                    We serve a webpage at the port 80-443 of our scanning servers, stating who we are and explaining the project.
                                </li>
                                <li>
                                Also, this is the list of IP addresses we perform our scans from:
                                    <ul>
                                        <li>139.162.197.221</li>
                                        <li>178.79.130.249</li>
                                        <li>139.162.164.105</li>
                                    </ul>
                                </li>
                            </ul>
                            6. OK,&nbsp; if it is for research, I have no problem with it.<br>
                            <ul>
                              <li>&nbsp;&nbsp;&nbsp; Thanks so much! We appreciate it.<br>
                              </li>
                            </ul>
                            7. No, I want you to stop pinging my computer/network<br>
                            <ul>
                              <li>&nbsp;&nbsp;&nbsp; Sure thing, just write to us ( <strong> securitynotification [at] sabanciuniv.edu</strong> ) 
                                and we will include your address/prefix in our blocklist list. The requested IPs/ranges will be excluded from following scans. 
                            </li>
                            </ul>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "FAQ"
}
</script>

<style>

</style>