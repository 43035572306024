import AuthService from '../services/auth.service';
import UserService from '../services/user.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        response_data => {
          if(response_data.result == 1){
            commit('loginSuccess', response_data.user);
            return Promise.resolve(response_data.user);
          }
          else{
            commit('loginFailure');
            return Promise.reject(error);
          }
        },

/*        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
*/        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    retrieve_elastic({ commit }, user){
      UserService.getElasticSearch().then(
        response => {
          console.log(response)
          if(response.result == 1){
            return Promise.resolve((response.data));
          }
        },
        error => {
          return Promise.reject(error);
        }     
      )
    },
    retrieve_nas({ commit }, user){
      UserService.getNas().then(
        response => {
          if(response.result == 1){
            return Promise.resolve((response));
          }
        },
        error => {
          return Promise.reject(error);
        }     
      )
    }    
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
