<template>
  <div class="home">
    <HomeContent />
  </div>
</template>

<script>
import HomeContent from '@/components/HomeContent';

export default {
  name: 'Home',
  components: {HomeContent}
}
</script>
