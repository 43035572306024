<template>
    <div id="Navbar">

        <div class="container-fluid">
            <div class="row pb-2 pt-2 upper-nav">
                <div class="col-12 text-middle">
                    <img :src="logoUrl" class="nav-logo" style="width: auto" alt="Sabancı University Logo">

                    <span class="nav-logo-text">
                        Sabanci University Security Notifications Group
                    </span>
                </div>
            </div>
        </div>

        <nav class="navbar navbar-expand-lg navbar-dark bg-sabanci">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarColor03">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">Home
                        </router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Login</a>
                        <div class="dropdown-menu">
                            <router-link class="dropdown-item" to="/login/elasticsearch">Elasticsearch</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" to="/login/nas">NAS</router-link>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Remediation Advice</a>
                        <div class="dropdown-menu">
                            <router-link class="dropdown-item" to="/advice/elasticsearch">Elasticsearch</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" to="/advice/nas">NAS</router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/FAQ">FAQ</router-link>
                    </li>
                    <li class="nav-item" v-if="this.currentUser">
                      <a class="nav-link" href @click.prevent="logOut">LogOut
                      </a>
                    </li>
                </ul>
            </div>
        </nav>

    </div>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            logoUrl: "https://www.sabanciuniv.edu/sites/default/themes/su/assets/img/theme/logo.png",
        }
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      }
    },
    methods: {
      logOut() {
        this.$store.dispatch('auth/logout');
        this.$router.push('/');
      }
    }
}
</script>

<style>
.nav-item a{
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
.nav-item{
    padding: 0 .4vw;
}
.bg-sabanci{
    background-color: #004287;
}
.upper-nav{
    background-color: #ecf0f1;
}
.nav-logo{
    height: min(4vw, 75px);
}
.nav-logo-text{
    font-size:min(3vw, 36px);
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    line-height: 16%;
    display: inline-block;
    margin-left: min(8vw, 200px);
}
</style>