<template>
    <div id="Footer">
        <div class="container-fluid mt-5">
            <div class="row footer-row">
                <div class="col-12 text-center">
                    &copy; 2021 Sabanci University.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style>
.footer-row{
    background-color: #ecf0f1;
    border-top: 2px solid #bdc3c7;
    font-weight: bold;
    padding: 1vh 0;
    color: #2c3e50;
}
</style>