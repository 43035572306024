<template>
    <div id="ElasticAdvice">
        <div class="container-fluid mt-5">

            <div class="row">
                <div class="col-12 text-center">
                    <h1>No Authentication Elasticsearch Remediation</h1>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-lg-6 col-sm-12">
                    <div class="card width-80">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 text-left">
                                    <h4 class="text-center">Are you affected?</h4>                          
If you have an authentication mechanism implemented on your Elasticsearch, you should get a similar response to the following request.
<pre>
$ curl -XGET '{IP of your server}:9200/?pretty'        
{
  "error" : {
    "root_cause" : [
      {
        "type" : "security_exception",
        "reason" : "missing authentication credentials for REST request [/?pretty]",
        "header" : {
          "WWW-Authenticate" : "Basic realm=\"security\" charset=\"UTF-8\""
        }
      }
    ],
    "type" : "security_exception",
    "reason" : "missing authentication credentials for REST request [/?pretty]",
    "header" : {
      "WWW-Authenticate" : "Basic realm=\"security\" charset=\"UTF-8\""
    }
  },
  "status" : 401
}
</pre>
If you get a similar response to following. You haven't implemented any authentication mechanism and your data might be stolen, modified, deleted. 
<pre>
curl -XGET '{IP of your server}:9200/?pretty'
{
  "name" : "Demo",
  "cluster_name" : "Demo",
  "cluster_uuid" : "demo123",
  "version" : {
    "number" : "5.3.2",
    "build_hash" : "f0258b6685684c113bad94d91b8fa02a",
    "build_date" : "2019-02-16T12:29:21.532006Z",
    "build_snapshot" : false,
    "lucene_version" : "5.3.2",
    "minimum_wire_compatibility_version" : "5.3.0"
  },
  "tagline" : "You Know, for Search"
}
                                </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-12">
                    <div class="card width-80">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 text-left">
                                    <h4 class="text-center">How to fix it</h4>
Enable SSL and Security in the config file. Add the following lines to your config/elasticsearch.yml

xpack.security.transport.ssl.enabled: true xpack.security.enabled: true 

Then restart the elasticsearch and setup the passwords for the users as follows:
<pre>
$ bin/elasticsearch-setup-passwords interactive
Initiating the setup of passwords for reserved users elastic,apm_system,kibana,kibana_system,
 logstash_system,beats_system,remote_monitoring_user.
You will be prompted to enter passwords as the process progresses.
Please confirm that you would like to continue [y/N]y
Enter password for [elastic]: 
Reenter password for [elastic]: 
Enter password for [apm_system]: 
Reenter password for [apm_system]: 
Enter password for [kibana_system]: 
Reenter password for [kibana_system]: 
Enter password for [logstash_system]: 
Reenter password for [logstash_system]: 
Enter password for [beats_system]: 
Reenter password for [beats_system]: 
Enter password for [remote_monitoring_user]: 
Reenter password for [remote_monitoring_user]: 
Changed password for user [apm_system]
Changed password for user [kibana_system]
Changed password for user [kibana]
Changed password for user [logstash_system]
Changed password for user [beats_system]
Changed password for user [remote_monitoring_user]
Changed password for user [elastic]
</pre>

Further, you need to generate TLS certificates and configure your elasticsearch to secure your communications. You can follow the <a href="https://www.elastic.co/guide/en/elasticsearch/reference/7.9/configuring-tls.html">elasticsearch documentations</a> for that.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="card width-80">
                     <div class="card-body">
                        <div class="col-12 text-center">
                           For more details about the authentication mechanisms of Elasticsearch, you can visit:</br><a href="https://www.elastic.co/guide/en/elasticsearch/reference/current/get-started-authentication.html">https://www.elastic.co/guide/en/elasticsearch/reference/current/get-started-authentication.html</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ElasticAdvice"
}
</script>

<style>

</style>