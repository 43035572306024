<template>
    <div id="HomeContent">


<!--           img-width="1024"
          img-height="480" -->

        <div class="container-fluid mt-5">
            <div class="row justify-content-center pr-5 pl-5 pb-5">
                <div class="col-sm-11">
                    <b-carousel
                      id="carousel-1"
                      fade
                      v-model="slide"
                      :interval="5000"
                      controls
                      indicators
                      background="#ccc"
                      img-width=1024
                      img-height=480
                      style="text-shadow: 1px 1px 2px #000;"
                    >
                      <!-- Text slides with image -->
                      <b-carousel-slide img-src="./images/slider1.jpg">
                              <b-col>
                                <b-button variant="primary" size="lg" to="/FAQ">Discover FAQ</b-button></b-col>
                      </b-carousel-slide>

                      <b-carousel-slide img-src="./images/slider2.png">
                          <b-col><b-button variant="dark" size="lg" href="https://sec.sabanciuniv.edu/en">Discover Cyber Security group</b-button></b-col>
                      </b-carousel-slide>

                      <b-carousel-slide img-src="./images/slider3.jpg">
                          <b-col>
                            <h1>We are also carrying out research on phishing attack detection.</h1>
                        </b-col>
                      </b-carousel-slide>

                    </b-carousel>
                </div>
            </div>
<!--             <div class="row">
                <div class="col-12 pr-5 pl-5">

                    <carousel :autoplay="true" :perPage="1" :autoplayTimeout="2000" :autoplayHoverPause="true">
                        <slide>
                            <div class= "jumbotron text-center">
                                <img src="images/slider1.jpg">
                            </div>
                        </slide>
                        <slide>
                            <div class="jumbotron text-center">
                                <h2>
                                    Welcome to, <br> Sabanci University Security Notification Group's Remediation Website
                                </h2>
                            </div>
                        </slide>
                        <slide>
                            <router-link class="jumbotron-linker" to="/">
                                <div class="jumbotron text-center">
                                    <h2>
                                        Learn more Sabanci University<br>
                                        <strong>remediation.sabanciuniv.edu/login/elasticsearch</strong>
                                    </h2>
                                </div>
                            </router-link>
                        </slide>
                    </carousel>

                </div>
            </div>
 -->            <div class="row pr-5 pl-5">
                <div class="col">
                    <div class="card fixed-card">
                        <div class="card text-center">
                            <div class="card-body d-flex flex-column">
                                <h4 class="card-title">Who we are and what we do</h4>
                                <p class="card-text">
                                    We are cyber security researchers from Sabancı University. Our aim is to facilitate remediation of vulnerable servers by helping internet mediaries and online resource owners.
                                </p>
                                <router-link class="btn btn-outline-primary btn-block mt-auto" to="/FAQ">Contact us to learn more</router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card fixed-card">
                        <div class="card text-center">
                            <div class="card-body d-flex flex-column">
                                <h4 class="card-title">Notifications and remediation</h4>
                                <p class="card-text">
                                    If you are notified by us about vulnerable servers in your network, please
                                    login to see them. You credentials are given in the email.
                                </p>
                                <div class="btn-group-vertical mt-auto">
                                    <router-link class="btn btn-outline-primary btn-block" to="/login/nas">NAS Login</router-link>
                                <router-link class="btn btn-outline-primary btn-block" to="/login/elasticsearch">Elasticsearch Login</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card fixed-card">
                        <div class="card text-center">
                            <div class="card-body d-flex flex-column">
                                <h4 class="card-title">Remediation Help</h4>
                                <p class="card-text">
                                    We provide a short guideline for admins on how to secure their
                                    online storage systems to avoid becoming a victim of ransomware,
                                    data theft and cryptocurrency mining operations.
                                    The golden rule is add authentication with relatively strong
                                    password.
                                </p>
                                <div class="btn-group-vertical mt-auto">
                                    <router-link class="btn btn-outline-primary btn-block" to="/advice/nas">Learn More for NAS</router-link>
                                <router-link class="btn btn-outline-primary btn-block" to="/advice/elasticsearch">Learn more for Elasticsearch</router-link>
                                </div>

<!--                                 <router-link class="btn btn-outline-primary btn-block mt-auto" to="/">Learn more</router-link> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
/*import {Carousel, Slide} from 'vue-carousel';
*/
export default {
    name: "HomeContent",
/*    components: {'carousel': Carousel, 'slide': Slide},*/
    data() {
        return {

        }
    },
    methods:{

    }
}
</script>

<style>
.fixed-card .card-body a{
    vertical-align: bottom;
}
.fixed-card .card-body{
    min-height: 35vh;
}
/*.jumbotron{
    color: #2c3e50;
    min-height: 40vh;
    text-align: center;
    vertical-align: middle;
}
.jumbotron-linker:hover{
    text-decoration: none;
}*/
</style>