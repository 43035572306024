<template>
    <div id="VulnDev">
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "VulnDevContent",
    data(){
        return {
            alert: {
                isAlert: false,
                isDanger: false,
                message: ""
            },
            type: {
                raw: this.$route.params.type,
                beautified: (this.$route.params.type.charAt(0).toUpperCase() + this.$route.params.type.slice(1))
            }
        }
    },
    watch: {
        '$route.params.type': function (id) {
            this.setData()
        }
    },
    methods: {
        raiseAlert(message, isDanger = true){
            this.alert.isAlert = true;
            this.alert.isDanger = isDanger;
            this.alert.message = message;
        },
        setData(){
            this.type = {
                raw: this.$route.params.type,
                beautified: (this.$route.params.type.charAt(0).toUpperCase() + this.$route.params.type.slice(1))
            }
        }
    },
    created(){
        if(this.type.raw !== "nas" && this.type.raw !== "elasticsearch") this.$router.push("/");
        this.setData();
    }
}
</script>

<style>

</style>